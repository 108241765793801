<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col class="d-flex align-items-center justify-content-start" sm>
        <b-dropdown
          id="dropdown-left"
          variant="success"
          class="my-2 mr-3"
          :disabled="isLoadingButton"
        >
          <template #button-content>
            <div
              class="d-inline-flex sk-three-bounce custom-loading-bounce"
              v-if="isLoadingButton"
            >
              <div class="sk-child sk-bounce1"></div>
              <div class="sk-child sk-bounce2"></div>
              <div class="sk-child sk-bounce3"></div>
            </div>
            <span class="mr-2" v-else>Generate Report</span>
          </template>

          <b-dropdown-item @click="handleGenerate('CSV')">
            CSV
          </b-dropdown-item>
          <b-dropdown-item @click="handleGenerate('XLSX')">
            XLSX
          </b-dropdown-item>
        </b-dropdown>
        <b-badge class="badge-info-generate" v-show="count > 0">
          <p class="badge-info-count">
            {{ count }}
          </p>
          <p>
            <strong>Report is being processed,</strong> check the
            <router-link :to="{ name: 'Report Transaction History' }">
              Report Transaction History
            </router-link>
            menu periodically to download the file.
          </p>
        </b-badge>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search By Transaction Type :"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="type"
              :options="typeOptions"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Email, Phone, Invoice No"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email, Phone, Invoice No ..."
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                :disable="false"
                :autoUpdate="false"
                :timePicker="true"
                @picker="doDateFilter"
              >
              </range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <b-button
          :disabled="stateButton"
          :style="buttonStyle"
          class="mb-3"
          @click="doFilter"
        >
          Apply the Filter
        </b-button>
      </b-col>
    </b-row>
    <div v-if="isLoadingTable">
      <b-skeleton-table
        :rows="10"
        :columns="11"
        :table-props="{ bordered: true }"
      >
      </b-skeleton-table>
    </div>
    <vuetable
      v-else
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="limit"
      :data-manager="dataManager"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="name-slot" slot-scope="prop">
        <router-link
          :to="onAction('view-user', prop.rowData, prop.rowIndex)"
          target="_blank"
          >{{ prop.rowData.users.name }} ({{
            prop.rowData.users.email
          }})</router-link
        >
        <span></span>
      </template>
      <template slot="type-slot" slot-scope="prop">
        <span>{{ prop.rowData.transaction_type }}</span>
      </template>
      <template slot="invoice_number-slot" slot-scope="prop">
        <span>{{
          prop.rowData.invoice_number == null
            ? "-"
            : prop.rowData.invoice_number
        }}</span>
      </template>
      <template slot="source_crypto-slot" slot-scope="prop">
        <span>{{ prop.rowData.source_crypto }}</span>
      </template>
      <template slot="asset_amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.asset_amount }} </span>
      </template>
      <template slot="trs_amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.trs_amount }} </span>
      </template>
      <template slot="amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.amount }} </span>
      </template>
      <template slot="status-slot" slot-scope="prop">
        <span>{{ prop.rowData.transaction_status }} </span>
      </template>
      <template slot="buying_rate-slot" slot-scope="prop">
        <span>{{ prop.rowData.buying_rate }} </span>
      </template>
      <template slot="selling_rate-slot" slot-scope="prop">
        <span>{{ prop.rowData.selling_rate }} </span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button
            class="btn btn-success"
            @click="onAction('detail-item', prop.rowData)"
          >
            Detail
          </button>
        </div>
      </template>
    </vuetable>
    <div
      class="vuetable-pagination ui basic segment grid"
      v-show="!isLoadingTable"
    >
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="current_page === 1" @click="doMovePage('first')">
            <i
              v-if="current_page === 1"
              class="fa fa-angle-double-left disabled"
            ></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="current_page === 1" @click="doMovePage('prev')">
            <i v-if="current_page === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.current_page }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      count: 0,
      page: 1,
      current_page: 1,
      limit: 10,
      data: [],
      startDate: "",
      endDate: "",
      startDateExport: "",
      endDateExport: "",
      type: "",
      filterText: "",
      isLoading: false,
      isLoadingTable: false,
      isLoadingButton: false,
      errors: {
        code: "",
        message: "",
        status: ""
      },
      typeOptions: [
        { value: "", text: "All" },
        { value: "beli_asset", text: "Beli Asset" },
        { value: "jual_asset", text: "Jual Asset" },
        { value: "top_up", text: "Top Up" },
        { value: "buy_gold", text: "Buy Gold" },
        { value: "sell_gold", text: "Sell Gold" },
        { value: "withdraw", text: "Withdraw" },
        { value: "jamimas", text: "Jamimas" },
        { value: "zakat", text: "Zakat" },
        { value: "gift_card", text: "Gift Card" },
        { value: "panen_emas", text: "Panen Emas" }
      ],
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `wallet-transaction`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "wallet_transaction.created_at|desc",
      sortOrder: [
        {
          field: "wallet_transaction.created_at",
          sortField: "wallet_transaction.created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          sortField: "wallet_transaction.created_at",
          title: "Date"
        },
        {
          name: "name-slot",
          sortField: "users.name",
          title: "User"
        },
        {
          name: "type-slot",
          title: "Transaction Type"
        },
        {
          name: "invoice_number-slot",
          title: "Invoice"
        },
        {
          name: "amount-slot",
          title: "IDR Amount"
        },
        {
          name: "status-slot",
          title: "Transaction Status"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ]
    };
  },
  computed: {
    stateButton() {
      const filters =
        this.type === "" &&
        this.filterText === "" &&
        this.startDate === "" &&
        this.endDate === "";
      const params =
        Object.entries(this.moreParams).filter(([key, value]) => value)
          .length == 0;

      return filters && params;
    },
    buttonStyle() {
      return {
        background: !this.stateButton ? "#7565F6" : "#c8ced3",
        color: !this.stateButton ? "#FFFFFF" : "inherit"
      };
    }
  },
  watch: {
    data(newVal, oldVal) {
      if (
        this.$refs.vuetable &&
        typeof this.$refs.vuetable.refresh === "function"
      ) {
        this.$refs.vuetable.refresh();
      }
    },
    count() {
      // if (this.count > 0) {
      //   setTimeout(() => {
      //     this.count = 0;
      //   }, 60000);
      // }
    }
  },
  methods: {
    onFetchNotification() {
      this.$http
        .get("report-transaction/on-process-count/wallet")
        .then(response => {
          const countProgress = response.data.data.on_process_count;
          this.count = countProgress;

          // if (countProgress > 0) {
          //   setTimeout(() => {
          //     this.count = 0;
          //   }, 60000);
          // }
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    onFetch() {
      const newParams = Object.entries(this.moreParams)
        .filter(([key, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      this.isLoadingTable = true;
      this.data = [];

      this.$http
        .get(
          `wallet-transaction?sort=${encodeURIComponent(this.sort)}&page=${
            this.page
          }&per_page=${this.limit}&${newParams}`
        )
        .then(response => {
          const datas = response.data.data;
          const datasCount = datas.length;
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = Math.min(
            startIndex + this.limit,
            datasCount > 0 ? datasCount + startIndex : this.limit
          );

          this.data = datas;
          this.current_page = response.data.current_page;
          this.paginationInfo =
            datas.length > 0
              ? `Displaying ${startIndex + 1} to ${endIndex} of ${
                  response.data.total
                } items`
              : "No relevant data";

          this.isLoadingTable = false;
        })
        .catch(err => {
          this.isLoadingTable = false;
          this.handleLoadError(err);
        });
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (local.length < 1) return;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onAction(action, data) {
      if (action == "view-user") {
        return { name: "Detail End User", params: { id: btoa(data.users.id) } };
      } else if (action == "detail-item") {
        const routeData = this.$router.resolve({
          name: "Detail Wallet Transaction History",
          params: { id: btoa(data.id) }
        });
        window.open(routeData.href, "_blank");
      }
    },
    npwpVal(value) {
      return value != null ? value : "-";
    },
    resetFilter() {
      this.filterText = "";
    },
    exportTable() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else if (this.startDateExport != "" && this.endDateExport != "") {
        startDate = this.startDateExport;
        endDate = this.endDateExport;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD HH:mm");
        startDate = this.$setDate
          .subtract(6, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm");
        this.endDateExport = endDate;
        this.startDateExport = startDate;
        this.$setDate.add(6, "days");
      }

      const start = moment(startDate).format("YYYY-MM-DD HH:mm");
      const end = moment(endDate).format("YYYY-MM-DD HH:mm");

      this.$http
        .get(
          `wallet-transactions?startDate=${start}&endDate=${end}&filter=${encodeURIComponent(
            this.filterText
          )}`
        )
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    allcap(value) {
      return value.toUpperCase();
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      // this.$refs.pagination.setPaginationData(paginationData);
      // this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    doFilter() {
      this.$events.$emit("filter-set");
    },
    onFilterSet() {
      this.page = 1;
      this.moreParams = {
        start_from: this.startDate,
        end_to: this.endDate,
        filter: encodeURIComponent(this.filterText),
        type: this.type
      };
      this.onFetch();
    },
    doDateFilter(value) {
      this.$events.fire("date-set", value);
    },
    onDateSet(value) {
      this.startDate = moment(value.startDate).format("YYYY-MM-DD HH:mm");
      this.endDate = moment(value.endDate).format("YYYY-MM-DD HH:mm");
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
    },
    handleGenerate(ext) {
      this.isLoadingButton = true;

      const newParams = Object.entries(this.moreParams)
        .filter(([key, value]) => value)
        .map(
          ([key, value]) =>
            `${
              key == "start_from"
                ? "startDate"
                : key == "end_to"
                ? "endDate"
                : key
            }=${value}`
        )
        .join("&");

      this.$http
        .get(
          `wallet-transactions?fileFormat=${ext.toLowerCase()}${
            newParams ? `&${newParams}` : ""
          }`
        )
        .then(result => {
          const countProgress = result.data.meta.data.on_process_count;
          this.count = countProgress;

          // if (countProgress > 0) {
          //   setTimeout(() => {
          //     this.count = 0;
          //   }, 60000);
          // }

          this.isLoadingButton = false;
        })
        .catch(error => {
          const err = error.response;
          if (err) {
            const code = err.data.code;
            const message = err.data.message;
            const status = err.data.status;

            this.isLoadingButton = false;
            this.$swal.fire(status, message, "error");
          }
        });
    }
  },
  mounted() {
    this.onFetch();
    this.onFetchNotification();
    this.$events.$on("filter-set", () => this.onFilterSet());
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
  }
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-info-generate {
  height: 32px;
  padding: 8px 12px;
  background-color: #fff3d3;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-info-count {
  width: 18px;
  height: 18px;
  margin: 0 6px 0 0;
  background-color: #ffce50;
  border-radius: 100px;
}

.custom-loading-bounce {
  width: 72px !important;
  height: 18px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.custom-loading-bounce .sk-child {
  width: 6px !important;
  height: 6px !important;
  background: #ffffff !important;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
